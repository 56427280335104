import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import Calendar from 'rc-year-calendar'
import 'rc-year-calendar/locales/rc-year-calendar.it'
import tippy from 'tippy.js'
import withRouter from 'utils/withRouter.js'
import { getDashboard, getUser, resetCurrentUser } from 'store/users'
import moment from 'moment'
import NavBar from 'components/common/navbar'
import DatesFilter from '../system_wide/dates_filter'

const mstp = state => {
  let { current_user, current_dashboard } = state.users
  return { current_user, current_dashboard }
}

class DashboardUser extends React.Component {
  static defaultProps = {
    current_dashboard: {},
  }

  state = {
    visible_months: [],
    minDate: null,
    maxDate: null,
  }

  async componentDidMount() {
    let user_id = this.props.router.params.id ?? null
    await this.props.dispatch(getUser(user_id))
    await this.props.dispatch(getDashboard())

    let { current_dashboard: d } = this.props
    let sub = d?.subscriptions?.[0] ?? {}
    let minDate = moment(sub?.start_at ?? new Date()).toDate()
    let maxDate = moment(sub?.start_at ?? new Date())
      .add(sub?.duration ?? 0, 'months')
      .toDate()

    this.setState({ minDate, maxDate })
  }

  componentWillUnmount() {
    this.props.dispatch(resetCurrentUser())
  }
  goBack = () => {
    this.props.router.navigate(-1)
    this.props.dispatch(resetCurrentUser())
  }

  onYearChanged = ({ currentYear }) => {
    let { minDate, maxDate } = this.state
    if (!minDate || !maxDate) {
      let { current_dashboard: d } = this.props
      let sub = d?.subscriptions?.[0] ?? {}
      minDate = moment(sub?.start_at ?? new Date()).toDate()
      maxDate = moment(sub?.start_at ?? new Date())
        .add(sub?.duration ?? 0, 'months')
        .toDate()
    }
    let visible_months = []
    Array.from(Array(12).keys()).forEach(m => {
      let mm = m + 1
      let monthFirst = moment(`${currentYear}-${mm}-01`).toDate()
      let monthLast = moment(`${currentYear}-${mm}`).endOf('month').toDate()
      if ((minDate < monthLast && maxDate >= monthLast) || (maxDate >= monthFirst && maxDate < monthLast)) {
        visible_months.push(mm - 1)
      }
    })
    this.setState({ visible_months, currentYear })
  }

  handleDayEnter(e) {
    if (e.events.length > 0) {
      var content = ''

      for (var i in e.events) {
        content += `<div class="bg-white border align-items-center p-2 shadow d-flex">
          <div style="width: 20px; height: 20px; background-color: ${e.events[i].color}; margin-right: 10px;"></div>${e.events[i].start} - ${e.events[i].status ?? 'Prenotata'}</div>`
      }

      if (this.tooltip != null) {
        this.tooltip.destroy()
        this.tooltip = null
      }

      this.tooltip = tippy(e.element, {
        placement: 'right',
        content: content,
        arrow: true,
        allowHTML: true,
        hideOnClick: 'mouseenter',
      })
      this.tooltip.show()
    }
  }

  handleDayLeave() {
    if (this.tooltip !== null) {
      this.tooltip.destroy()
      this.tooltip = null
    }
  }
  onChangeDatesFilter = dates => {
    let minDate = null
    let maxDate = null
    let currentYear = moment().format('YYYY')
    if (dates.length === 2) {
      minDate = moment(dates[0]).toDate()
      maxDate = moment(dates[1]).toDate()
      currentYear = dates[0].split('-')[0]
    } else {
      let { current_dashboard: d } = this.props
      let sub = d?.subscriptions?.[0] ?? {}
      minDate = moment(sub?.start_at ?? new Date()).toDate()
      maxDate = moment(sub?.start_at ?? new Date())
        .add(sub?.duration ?? 0, 'months')
        .toDate()
      currentYear = sub?.start_at?.split('-')?.[0] ?? moment().format('YYYY')
    }
    this.setState({ minDate, maxDate, currentYear })
  }

  render() {
    let { current_user: c, current_dashboard: d } = this.props
    let { minDate, maxDate, currentYear } = this.state
    let noInfo = Object.keys(d).length === 0
    let sub = d?.subscriptions?.[0] ?? {}
    let payments = d?.payments ?? []
    let payments_total = 0
    let trs = d?.trainings ?? []
    let totals = trs
      .filter(el => {
        if (minDate <= el.startDate && el.startDate <= maxDate) return el
        else return null
      })
      .reduce(
        (acc, el) => {
          if (el?.status === null) {
            acc['Prenotata'] = acc['Prenotata'] + 1
          } else {
            acc[el.status] = acc[el.status] + 1
          }
          return acc
        },
        { Prenotata: 0, Recupero: 0, Extra: 0 }
      )

    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Dashboard" />
        <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-100 w-100">
          <div className="d-flex flex-column flex-fill overflow-auto">
            <div className="container-fluid px-0 mb-2">
              <div className="d-flex flex-wrap gap-2">
                <Button onClick={this.goBack}>
                  <i className="fas fa-arrow-left fa-fw text-start" />
                  Indietro
                </Button>
                <DatesFilter onSubmit={this.onChangeDatesFilter} />
              </div>
            </div>
            <Card className="mb-4">
              <Card.Header>
                <Card.Title>
                  {c.last_name} {c.first_name}
                </Card.Title>
              </Card.Header>
              <Card.Body className="bg-white">
                <Row>
                  <Col>
                    <div>
                      Codice fiscale: <b>{c.fiscal_code}</b>
                    </div>
                    <div>
                      Data di nascita: <b>{c.birthday_date}</b>
                    </div>
                    <div>
                      Luogo di nascita:{' '}
                      <b>
                        {c.birthday_city} ({c.birthday_province})
                      </b>
                    </div>
                    <div>
                      Telefono: <b>{c.telephone_number}</b>
                    </div>
                    <div>
                      Email: <b>{c.email}</b>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Data registrazione: <b>{c.registration_date}</b>
                    </div>
                    <div>
                      Genere: <b>{c.gender}</b>
                    </div>
                    <div>
                      Livello: <b>{c.level}</b>
                    </div>
                    <div>
                      Scadenza certificato: <b>{c.certificate_expired_at}</b>
                    </div>
                    <div>
                      Matricola AIBVC: <b>{c.aibvc_registration_number}</b>
                    </div>
                  </Col>
                  <Col>
                    {!noInfo && (
                      <>
                        <div>
                          Sottoscrizione: <b>{sub?.name ?? '-'}</b>
                        </div>
                        <div>
                          Data inizio: <b>{sub?.start_at ?? '-'}</b>
                        </div>
                        <div>
                          Data scadenza: <b>{sub?.expiration_date ?? '-'}</b>
                        </div>
                        <div>
                          Costo: <b>{sub?.price ?? '-'}</b>
                        </div>
                        <div>
                          Attiva:{' '}
                          {sub?.enabled === 1 ? (
                            <i className="fa fa-check text-success fw-bold" />
                          ) : (
                            <i className="fa fa-times text-danger fw-bold" />
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Row>
              <Col className="col-lg-8 col-md-12 col-sm-12">
                <Card className="mb-4">
                  <Card.Header>
                    <Card.Title>Allenamenti</Card.Title>
                  </Card.Header>
                  <Card.Body className="bg-white">
                    {!noInfo && (
                      <>
                        <style jsx="true" global="true">
                          {`
                            .months-container {
                              overflow: hidden;
                              display: flex !important;
                              flex-wrap: wrap !important;
                            }
                            .month-container {
                              visibility: hidden !important;
                              height: 0px !important;
                            }
                            ${this.state.visible_months
                              .map(
                                m => `div[data-month-id="${m}"] {
                                    visibility: visible !important;
                                    height: unset !important;
                                  }`
                              )
                              .join(' ')}
                          `}
                        </style>
                        <Calendar
                          className="p-3"
                          style="background"
                          minDate={minDate}
                          maxDate={maxDate}
                          year={currentYear}
                          language="it"
                          weekStart={1}
                          onYearChanged={this.onYearChanged}
                          dataSource={trs}
                          onDayEnter={e => this.handleDayEnter(e)}
                          onDayLeave={() => this.handleDayLeave()}
                        />
                        <div className="mt-3">
                          <div className="d-flex flex-row align-items-center">
                            <div style={{ width: 20, height: 20, backgroundColor: 'lightblue', marginRight: 10 }}></div>
                            Totale prenotazioni:{' '}
                            <span style={{ marginLeft: 5, fontWeight: 'bold' }}>{totals['Prenotata']}</span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div style={{ width: 20, height: 20, backgroundColor: 'pink', marginRight: 10 }}></div>
                            Totale recuperi:{' '}
                            <span style={{ marginLeft: 5, fontWeight: 'bold' }}>{totals['Recupero']}</span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div style={{ width: 20, height: 20, backgroundColor: 'orange', marginRight: 10 }}></div>
                            Totale extra: <span style={{ marginLeft: 5, fontWeight: 'bold' }}>{totals['Extra']}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-lg-4 col-md-12 col-sm-12">
                <Card className="mb-4">
                  <Card.Header>
                    <Card.Title>Pagamenti</Card.Title>
                  </Card.Header>
                  <Card.Body className="bg-white">
                    {payments.length > 0 && (
                      <Table className="mt-4" striped bordered hover>
                        <thead>
                          <tr>
                            <th>Data</th>
                            <th className="text-end">Importo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.map(p => {
                            payments_total = payments_total + p.amount
                            return (
                              <tr>
                                <td>{p.created_at.split('T')[0]}</td>
                                <td className="text-end">{p.amount} €</td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Totale</th>
                            <th
                              className={`text-end ${payments_total === sub.price ? 'bg-success text-white' : 'bg-warning'}`}>
                              {payments_total} €
                            </th>
                          </tr>
                        </tfoot>
                      </Table>
                    )}
                    {payments.length === 0 && <div className="border p-3 mb-3">Nessun pagamento ricevuto</div>}
                    {payments_total < sub.price && (
                      <div className="text-danger text-end">
                        <b>NB</b>: Abbonamento non saldato completamente!
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(withRouter(DashboardUser))
